<template>
  <div id="sec-submit-popup">
    <div class="submit-holder">
      <div class="submit-image"></div>
      <div class="submit-text">
        <span>Отправлено!</span>
        <br />Скоро мы подготовим <br />документ и отправим его
      </div>
      <div class="ok-button" @click="closeSubmitPopup()">ОК</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sec-submit-popup",
  methods: {
    closeSubmitPopup() {
      this.$emit("closeSubmitPopup");
    }
  }
};
</script>

<style lang="scss">
#sec-submit-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-items: center;
  background-color: rgba($color: #000000, $alpha: 0.5);

  .submit-holder {
    height: 300px;
    max-width: 500px;
    min-width: 320px;
    width: 35%;
    background-color: white;

    .submit-image {
      margin-top: 10px;
      margin-bottom: 6px;
      width: 100%;
      height: 143px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-image: url("~@/assets/img/ok.svg");
    }

    .submit-text {
      text-align: center;
      font-size: 18px;
      line-height: 22px;

      span {
        font-size: 22px;
        line-height: 24px;
      }
    }
    .ok-button {
      @include button-main;
      margin-top: 15px;
      width: 180px;
      margin-left: auto;
      margin-right: auto;
      background: linear-gradient(
        90deg,
        rgba(33, 71, 211, 0.5) 0%,
        rgba(91, 179, 149, 0.5) 100%
      );
    }
  }
}
</style>
